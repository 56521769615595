<template>
    <div>
        <PageTitle title="Evaluation"></PageTitle>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto text-center">
                        <h3 class="section-title">Achievement Table</h3>
                    </div>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" style="width: 5%;">ID</th>
                            <th scope="col">Requirement</th>
                            <th scope="col">Priority</th>
                            <th scope="col">State</th>
                            <th scope="col">Delivery date</th>
                            <th scope="col">Contributors</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Requirement modelling</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>October</td>
                            <td>Henry, John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Research</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>October</td>
                            <td>Henry, John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Choose the technologies and languages for each part of the project</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>October</td>
                            <td>Henry, John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Mobile & web UI and prototype designs</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>November</td>
                            <td>Henry, John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Building the UI in flutter and web console</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>November</td>
                            <td>John</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Start building the Unity AR Application with 3D avatars using Vuforia</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>November</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Integrate the 3D unity interface with the flutter front-end app</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>November</td>
                            <td>Henry, John</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Building voice recognition and QR scanning function in the app</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>November</td>
                            <td>Henry, John</td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Add a selection of 3D avatar for users to choose from</td>
                            <td>Should</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <td>User can choose which voice they would AR avatar user</td>
                            <td>Should</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>John</td>
                        </tr>
                        <tr>
                            <th scope="row">11</th>
                            <td>Adjust and improve UI designs for app</td>
                            <td>Should</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>Henry, John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">12</th>
                            <td>Start the backend server development using Node.js and express</td>
                            <td>Could</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">13</th>
                            <td>Conversation Chat bubble to visualize chat histories</td>
                            <td>Could</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>John</td>
                        </tr>
                        <tr>
                            <th scope="row">14</th>
                            <td>Achieve simple conversation replies from the avatar in flutter app</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>December</td>
                            <td>John</td>
                        </tr>
                        <tr>
                            <th scope="row">15</th>
                            <td>User can see a history list of cards they have viewed</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>Henry, John</td>
                        </tr>
                        <tr>
                            <th scope="row">16</th>
                            <td>User can set cards as favourites and view them in a list</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>Henry, John</td>
                        </tr>
                        <tr>
                            <th scope="row">17</th>
                            <td>Search feature in the history page and favourite page</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>John, Jiayi</td>
                        </tr>
                        <tr>
                            <th scope="row">18</th>
                            <td>Add documentations to the node.js backend to help frontend development</td>
                            <td>Should</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">19</th>
                            <td>Set up backend database using MongoDB, user profile and login credential storage</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>Henry</td>
                        </tr>
                         <tr>
                            <th scope="row">20</th>
                            <td>Add user authentication and JWT in the backend</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>Henry</td>
                        </tr>
                         <tr>
                            <th scope="row">21</th>
                            <td>User can edit their profile in the flutter app and sync to the backend database</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>John, Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">22</th>
                            <td>User can upload their profile picture and store them in the backend</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>January</td>
                            <td>John, Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">23</th>
                            <td>Training and integrate IBM Watson Assistant AI into the backend</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>February</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row">24</th>
                            <td>Vue.js front-end features development (card lists, user profile edits, login)</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>February</td>
                            <td>Jiayi, John</td>
                        </tr>
                        <tr>
                            <th scope="row">25</th>
                            <td>Profile edited in vue.js web front-end can be synced into the backend</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>February</td>
                            <td>Jiayi</td>
                        </tr>
                         <tr>
                            <th scope="row">26</th>
                            <td>Finish the web front-end using Vue.js</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>March</td>
                            <td>John, Jiayi</td>
                        </tr>
                         <tr>
                            <th scope="row">27</th>
                            <td>Handles user questions about email, mobile and website contact</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>March</td>
                            <td>John, Henry</td>
                        </tr>
                         <tr>
                            <th scope="row">28</th>
                            <td>User profile card in Flutter, supports all the contact functions</td>
                            <td>Must</td>
                            <td>✅</td>
                            <td>March</td>
                            <td>John</td>
                        </tr>
                         <tr>
                            <th scope="row">29</th>
                            <td>User can record their own voice introduction instead of using TTS</td>
                            <td>Could</td>
                            <td>❌</td>
                            <td>Future</td>
                            <td>Henry, John</td>
                        </tr>
                        <tr>
                            <th scope="row">30</th>
                            <td>User can link their LinkedIn account for both the login and profile gathering</td>
                            <td>Could</td>
                            <td>❌</td>
                            <td>Future</td>
                            <td>Jiayi, John</td>
                        </tr>
                        <tr>
                            <th scope="row">31</th>
                            <td>Support any image target from any business cards</td>
                            <td>Should</td>
                            <td>❌</td>
                            <td>Future</td>
                            <td>Henry</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="3">Key functionalities</th>
                            <td colspan="3">100% completed</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="3">Optional functionalities</th>
                            <td colspan="3">70% completed</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>


        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto text-center">
                        <h3 class="section-title">Known<span>Bugs</span></h3>
                    </div>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" style="width: 2%;">ID</th>
                            <th scope="col">Description</th>
                            <th scope="col">Priority</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>In some devices, in very rare situation the application will crash at launch, and has to be force stopped to be able to open again</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>In some situation, at the first launch when given permissions, if the permission is not given in time, the application might need to relaunch to be able to use,
                                and in extreme cases it has to be force stopped to be able to open again</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Speech recognizer sometimes might be inaccurate</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Speech recognizer might cut off the user if the pause is too long</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>In some virtual keyboard input methods (such as Chinese), there will be glitches when entering username and password</td>
                            <td>Low</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto text-center">
                        <h3 class="section-title">Individual Contribution Table</h3>
                    </div>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">Work packages</th>
                            <th scope="col">Henry Zhang</th>
                            <th scope="col">John Zhang</th>
                            <th scope="col">Jiayi Chen</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">Project Partners liaison</th>
                            <td>50%</td>
                            <td>25%</td>
                            <td>25%</td>
                        </tr>
                        <tr>
                            <th scope="row">Requirement analysis</th>
                            <td>33%</td>
                            <td>33%</td>
                            <td>33%</td>
                        </tr>
                        <tr>
                            <th scope="row">UI Design</th>
                            <td>33%</td>
                            <td>33%</td>
                            <td>33%</td>
                        </tr>
                        <tr>
                            <th scope="row">Research and Experiments</th>
                            <td>40%</td>
                            <td>30%</td>
                            <td>30%</td>
                        </tr>
                        <tr>
                            <th scope="row">Programming</th>
                            <td>30%</td>
                            <td>35%</td>
                            <td>25%</td>
                        </tr>
                        <tr>
                            <th scope="row">Bi-weekly Reports</th>
                            <td>33%</td>
                            <td>33%</td>
                            <td>33%</td>
                        </tr>
                        <tr>
                            <th scope="row">Report Website</th>
                            <td>40%</td>
                            <td>20%</td>
                            <td>35%</td>
                        </tr>
                        <tr>
                            <th scope="row">Video Editing</th>
                            <td>50%</td>
                            <td>25%</td>
                            <td>25%</td>
                        </tr>
                        <tr>
                            <th scope="row">Overall Contribution</th>
                            <td>33%</td>
                            <td>33%</td>
                            <td>33%</td>
                        </tr>
                        <tr>
                            <th scope="row">Main Roles</th>
                            <td>Project Partner liaison, UI designer, researcher, programmer, report editor</td>
                            <td>UI designer, researcher, programmer, report editor</td>
                            <td>UI designer, researcher, report editor, tester</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>


        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto text-left">
                        <h3 class="section-title">Critical<span>Evaluation</span>of the Project</h3>
                        <h4>UI / UX</h4>
                            <p>We take user experience the priority during development. To improve user experience, we take the feedback from many people such as our friends, family or even the people who are working on UI/UX design.

                                Both of our web and mobile applications are using Material Design. Material design is a design language developed by Google. Since material design has support in flutter and Vue, this reduces the difficulty of UI implementation and develop responsibly web application.

                                We also improve the feedback from interaction when using the applications, such as add progress bar when loading, customize empty state when nothing is displayed and add animation effect on transition with curves for smooth transitions.</p>

                        <h4>Functionality</h4>
                            <p>We have achieved all of the requirements clients asked and most of the 'should' functionality has already mentioned. Our project has two front-end applications.
                                We purposefully added the web application considering for those who doesn't want to use or install an app to view their history or update their profiles, to extend the system's usability.
                                The android application can scan the QR code on the business card and view AR avatar. Both of the web application and android application are able to view and edit user profile, scan history and favorite list after logging in.
                                
                                Since the integrated Unity is not stable under the flutter framework, this could cause the programme crash irregularity. Apart from the Unity, all packages we used in the flutter is open-source and secure by the Dart team or Flutter team. To make sure the functionality of each package work as expected, we always choose the package published by the programmer verified by official and widely used by many developers.</p>

                        <h4>Stability</h4>
                            <p>Our app has been relatively stable as no major bug causes the application to crash during run time, and all of the functionality works well during the testing stage.
                                
                                During the development of our project, we tested every individual function of the application to make sure our app carries out the expected functionalities.
                                
                                After finishing development stage, our project has been tested on different kinds of testing including the API  passed stress testing. Meanwhile, the mobile and web application is fully tested under black box testing and white box testing. The application may issue problems under different OS or various Android versions. We will do further testing in the future if we have time.</p>

                        <h4>Efficiency</h4>
                        <h6>Mobile application</h6>
                            <p>
                                Overall, the efficiency of the application is acceptable. 
                                
                                We have mainly focused on the FPS(frames per second) of each page in the mobile app and web application. 
                                
                                The FPS(frames per second) varies in different page, but no page is lower than 70 FPS:
                            </p>
                        <div class="col-lg-10 mx-auto text-center">
                            <div class="row">
                                <div class="col-sm text-center">
                                    <img src="images/evaluation/ar.jpg" class="img-responsive item-shadow">
                                    <h6>Unity AR Page: 72FPS average</h6>
                                </div>

                                <div class="col-sm text-center">
                                    <img src="images/evaluation/login.jpg" class="img-responsive item-shadow">
                                    <h6>Login Page: 83 Fps average</h6>
                                </div>

                                <div class="col-sm text-center">
                                    <img src="images/evaluation/mycards.jpg" class="img-responsive item-shadow">
                                    <h6>MyCards Page: 110 Fps average</h6>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm text-center">
                                    <img src="images/evaluation/userprofile.jpg" class="img-responsive item-shadow">
                                    <h6>Profile Page: 100 Fps average</h6>
                                </div>

                                <div class="col-sm text-center">
                                    <img src="images/evaluation/scanqr.jpg" class="img-responsive item-shadow">
                                    <h6>Scan QR Code Page: 90 Fps average</h6>
                                </div>

                                <div class="col-sm text-center">
                                </div>

                            </div>
                        </div><br><br>

                        <h6>Web application</h6>
                                <p>
                                The Fps for web application is remain at 60 Fps, may reduce to about 40 Fps when refresh data:
                                </p>
                        <img src="images/evaluation/vue.png" class="img-responsive item-shadow">

                        <h4>Compatibility</h4>
                            <p>The web application is compatible in all browsers that are ES5-compliant (IE8 and below are not supported) either running on mobile or desktop.

                                Since flutter can run both on IOS or Android, due to the Vuforia is supported on Android platform only with flutter, our mobile application is compatible with Android devices that has Jelly Bean, v16, 4.1.x or newer operating systems.

                                When Vuforia is supported by flutter_unity_widget, we will update the mobile application that can run in IOS.</p>

                        <h4>Maintainability</h4>
                            <p>For making the developers that may work on it in the future more efficient, we have added comments to explain the code and wrote API documentation. 

                                It would be helpful for maintainability work. Also, there are a wide range of explanations about our project, which can be found on the report website.
                                
                                The mobile application use pubspec.yml(provide by flutter) to maintain the version of each packages required to build the project.</p>

                        <h4>Project Management</h4>
                            <p>We do team meeting once per week; during the meeting, we assign the task to each one equally. Any problem will be mentioned during the session, and we will solve the issue together.

                                We have been actively building and testing our code. While also keeping track any bugs and problems, managing progress using varies tools like Trello and GitHub issues. We will revise our plan based on current progress and problems, and keep updating our client and also taking suggestions and feedbacks.

                                For the git part, we used the dev branch for developing a new feature, and the dev branch will merge into master when a minimum of two members thoroughly tests the new function.</p>

                        <p>Cost: the only cost to manage the project right now is our Azure backend server. We have hosted our backend on Azure B1s virtual machine at a cost of £6.42 per month, provided by UCL. </p>
                    </div>
                </div>
            </div>
        </section>




        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto text-left">
                        <h3 class="section-title"><span>Future</span>Work</h3>
                        <p>If we had more time, we will try to add a voice function which is requested by the many, that users can record their own voice instead of using TTS.

                            We will also try to add more fields where user can put in their profile. This might include adding the LinkedIn OAuth function, where users can login using their
                            linkedIn account, and automatically fill their profile using LinkedIn data.

                            We will also try to deploy our flutter app to IOS and web.

                            For the IBM Watson Assistant, we might invest more time and writing another API for Watson to do this more programmatically, to make our AI more intelligent.
                            
                            For the unity side, we will add more functions such as customisable models, users can customize their own model properties such as height, hair colour, gender, skin or even the emotion. We will also try to better design our animations such as mouth and head follow.

                            Most importantly, we will also add support for users to use any image target for tracking, as now we are limited to only one. This could be done easily if we decide to upgrade our Vuforia engine plan in the future.

                            We would also like to do further tests to make sure our application is stable enough, to get more feedback from our testers and improve user experience.
                        </p>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
    import PageTitle from '../components/PageTitle.vue'
    export default {
        name: "achievement",
        components: {
            PageTitle
        }
    }
</script>

<style scoped>
    h4{
        margin-top: 30px;
    }
    img{
        margin: 30px 0;
        width: 100%;
    }
</style>